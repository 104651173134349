<template>
  <div class="app-form">
    <b-tabs>
      <b-tab-item label="Таблица">
<!--         <template v-for="(value, name) in model">
          <div class="field is-horizontal" v-bind:key="name">
            <div class="field-label is-normal">
              <label class="label">{{name}}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" :disabled="true" :value="value">
                </div>
              </div>
            </div>
          </div>
        </template> -->
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Наименование</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input" type="text" v-model="model.name">
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Текст</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <b-input maxlength="1024" type="textarea" v-model="model.text"></b-input>
              </div>
            </div>
          </div>
        </div>

        <template v-if="image">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение</label>
            </div>
            <div class="field-body">
              <img :src="getImgUrl(image)">
            </div>
            <p class="control">
              <a href="#" class="button is-danger" @click="delImage()">X</a>
            </p>
          </div>
        </template>
        <template v-else="">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение</label>
            </div>
            <div class="field-body">
              <b-field class="file">
                <b-upload v-model="file" @input="pushName" name="file">
                  <a class="button is-primary">
                    <b-icon icon="upload"></b-icon>
                    <span>Выберите файл</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="file">
                  {{ file.name }}
                </span>
              </b-field>
            </div>
          </div>
        </template>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Основная</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select expanded v-model="model.is_main">
                    <option
                      v-for="option in state_yesno"
                      :value="option.id"
                      :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Удаленная</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select expanded v-model="model.is_deleted">
                    <option
                      v-for="option in state_yesno"
                      :value="option.id"
                      :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label">
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button class="button is-primary" @click="doUpdate()">
                Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="model">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import {
  addPush
} from '@/api/push'
import {
  state_yesno,
  state_show
} from '@/utils'
import {
  uploadFile,
  delFile
} from '@/api/file'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {
  name: 'PushAdd',
  components: {
    VueJsonPretty
  },
  props: {
    //
  },
  data: function() {
    return {
      state_yesno: state_yesno,
      state_show: state_show,

      model: {
        is_main: false,
        is_deleted: false
      },

      // file
      image: null,
      file: null,
      file_name: undefined,

    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function() {
    //
  },
  mounted: function() {
    //
  },
  methods: {
    // Image
    pushName(file) {
      let $this = this
      if (file) {
        $this.file_name = file.name
        $this.doUpload()
      }
    },
    doUpload: function() {
      let $this = this
      let formData = new FormData();

      if ($this.file) {
        formData.append('file', $this.file)
        if ($this.file_name) {
          formData.append('name', $this.file_name)
        }
        uploadFile(formData, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data.data
            this.image = data.file_name
            this.model.file_id = data.id
            this.file = null
            this.file_name = null
          })
      }
    },
    getImgUrl(image) {
      return `/upload/${image}`
    },
    delImage() {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены что хотите удалить изображение',
        cancelText: 'Нет',
        confirmText: 'Да',
        onConfirm: () => {
          delFile(this.model.file_id, this.$store.state.jwt_user)
            .then(() => {
              this.model.file_id = null
              this.image = null
            })
        }
      })
    },

    doUpdate () {
      let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          addPush($this.model, this.$store.state.jwt_user).then(() =>
            {
              console.log($this.model)
              this.$router.push({ name: 'a-pushs', params: {} })
            }
          )        

        }
      })
    },
  },
}
</script>
<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style></style>